import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import OnbImg from '../../Images/image bg.png';
import classes from './LatestDashboard.module.css';
// import loanicon from '../../Images/moneys.png'
// import loaniconblue from '../../Images/moneysblue.png'
// import loanicon#2D995F from '../../Images/receipt-2.png'
// import axios from 'axios';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, ProgressBar } from 'react-bootstrap';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Link } from 'react-router-dom'
import MainDashboard from '../Main Dashboard/MainDashoard';
import One from '../../assets/dashimg/1.png'
import Two from '../../assets/dashimg/2.png'
import Three from '../../assets/dashimg/3.png'
import Four from '../../assets/dashimg/4.png'
import dChart5 from '../../assets/promix/dShart5.svg'
import dChart6 from '../../assets/promix/dShart6.svg'
import dChart7 from '../../assets/promix/dShart7.svg'
import dChart8 from '../../assets/promix/dShart8.svg'
import Arrow from '../../assets/promix/dArrow-down.svg'
import Revenue from '../../assets/nw/income.png'
import Expenses from '../../assets/nw/exp.png'
import Products from '../../assets/nw/product.jpg'
import Customers from '../../assets/nw/customers.png'
import AsyncStorage from '@react-native-async-storage/async-storage';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import CashDash from '../Cash Dash/CashDash';
import ImageIcon from '../../assets/promix/Group 1.png';
// import { Chart } from 'react-charts'
import LatestMenu from './LatestMenu';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';
import icon7 from '../../assets/promix/vuesax/log.png';
import LatestDash from './LatestDash';
import { Tabs, Tab, Container, Row, Col } from 'react-bootstrap';
import HamburgerMenu from '../../Component/Hamburger/HamburgerMenu';
import Chart from 'react-apexcharts';




const LatestAccountingDashboard = () => {
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [company, setCompany] = useState('');
    const [userType, setUserType] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [firstName, setFirstName] = useState('Sade Okoya');
    const [stat, setStat] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [recievables, setReceivables] = useState([]);
    const [newLoading, setNewLoading] = useState(false);
    const [validity, setValidity] = useState('');
    const [permits, setPermits] = useState([]);
    const [admin, setAdmin] = useState("");
    const [loading, setLoading] = useState(false);


    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('tobi');
            // const value2 = await AsyncStorage.getItem('permissions');
            const value3 = await AsyncStorage.getItem('admin');
            const value7 = await AsyncStorage.getItem('companyName');
            const value8 = await AsyncStorage.getItem('validity');
            const permitsss = await AsyncStorage.getItem('modules');

            if (value !== null) {
                setBearer(value);
            }
            if (permitsss !== null) {
                const parsedPermits = JSON.parse(permitsss);
                const permitNames = parsedPermits.map(permit => permit.name);
                setPermits(permitNames);
                // console.log(permitNames);
            }
            if (value1 !== null) {
                setUser(value1);
            }
            if (value8 !== null) {
                setValidity(value8);
            }
            // if (value2 !== null) {
            //   setPermissions(value2);
            // }
            if (value3 !== null) {
                setAdmin(value3);
            }
            if (value7 !== null) {
                setCompany(value7);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const data = [
        {
            label: 'Money In',
            data: [
                { primary: 'March 2024', secondary: 3_200_000 },
                { primary: 'April 2024', secondary: 2_800_000 },
                { primary: 'May 2024', secondary: 3_600_000 },
                { primary: 'June 2024', secondary: 4_000_000 },
            ],
        },
        {
            label: 'Money Out',
            data: [
                { primary: 'March 2024', secondary: 1_500_000 },
                { primary: 'April 2024', secondary: 2_000_000 },
                { primary: 'May 2024', secondary: 2_500_000 },
                { primary: 'June 2024', secondary: 3_000_000 },
            ],
        },
    ];

    const chartOptions = {
        primaryAxis: {
            getValue: datum => datum.primary,
            type: 'ordinal',
        },
        secondaryAxes: [
            {
                getValue: datum => datum.secondary,
                elementType: 'bar',
                min: 1_000_000,
                max: 4_000_000,
                tickFormat: d => `${(d / 1_000_000).toFixed(1)}M`,
            },
        ],
        getSeriesStyle: series => ({
            color: series.label === 'Money In' ? '#2D995F' : '#FFBA08',
        }),
    };

    const Legend = ({ data }) => (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {data.map(series => (
                <div key={series.label} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                    <div
                        style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: series.label === 'Money In' ? '#2D995F' : '#FFBA08',
                            marginRight: '10px',
                        }}
                    ></div>
                    <span>{series.label}</span>
                </div>
            ))}
        </div>
    );

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
        return formattedDate;
    }

    const handleLogout = async () => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${BASE_URL}/logout`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );

            navigate('/login');
            //   toast.success(response.data.message);
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response) {
                if (error.response.status === 401) {
                    navigate('/login');
                } else if (error.response.data && error.response.data.message) {
                    if (typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (Array.isArray(error.response.data.message)) {
                        errorMessage = error.response.data.message.join('; ');
                    } else if (typeof error.response.data.message === 'object') {
                        errorMessage = JSON.stringify(error.response.data.message);
                    }
                }
            }
            //   toast.error(errorMessage);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
        return formattedDate;
    }

    function padZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const changeName = () => {
        setFirstName('Ibrahim Awonuga');
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const chart = ApexCharts();

    const areaChartOptions = {
        chart: { type: 'area', height: 350 },
        xaxis: { categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] }, // You can change based on daily, weekly, etc.
        series: [
            { name: 'Income', data: [400, 430, 448, 470, 540, 580, 690] },
            { name: 'Expenses', data: [120, 150, 170, 180, 190, 200, 210] }
        ]
    };

    const barChartOptions = {
        chart: { type: 'bar', height: 350 },
        xaxis: { categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'] }, // You can change based on daily, weekly, etc.
        series: [{
            name: 'Net Profit',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        }, {
            name: 'Revenue',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        }, {
            name: 'Free Cash Flow',
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        yaxis: {
            title: {
                text: '$ (thousands)'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val + " thousands"
                }
            }
        }
    };

    // Pie Chart Data (Income from last 4 months)
    const pieChartOptions = {
        chart: { type: 'donut', height: 250 },
        labels: ['Month 1', 'Month 2', 'Month 3', 'Current Month'],
        series: [44, 55, 41, 67],
        legend: {
            show: false,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            labels: {
                colors: undefined,
            },
            markers: {
                width: 12,
                height: 12,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 5,
            },
        },
    };


    const fetchData8 = async () => {
        setNewLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/dashboard`, { headers });
            const sta = response.data?.data;
            const sta1 = response.data?.data?.top_customers;
            const sta2 = response.data?.data?.account_receivables;
            console.log(sta);
            setStat(sta);
            setCustomer(sta1);
            setReceivables(sta2);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Redirect to login page if unauthorized
                navigate('/login');
            } else {
                const errorStatus = error.response?.data?.message;
                console.log(errorStatus);
                setStat([]);
            }
        } finally {
            setNewLoading(false);
        }
    };

    useEffect(() => {
        if (bearer) {
            fetchData8();

        }
    }, [bearer]);

    return (
        <div className={classes.latestBody}>

            <div className={classes.latestBodyContainer}>
                <div className={classes.formSection}>
                    <LatestDash show={show} handleClose={handleClose} />
                    <div className={classes.body_style}>
                        <div className={classes.dashboardasdf112}>
                            <div >
                                <div className={classes.dashboardasdf112left}>
                                    <div className={classes.innerasdfas121}>
                                       
                                        <div style={{
                                            position: "absolute",
                                            top: "40px",
                                            left: "93px",
                                            transform: "translateX(-40%)",
                                            color: "#2D995F",
                                            fontWeight: 800,
                                            fontSize: "20px",
                                            textAlign: "right",
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all',
                                            maxWidth: "1200px",
                                        }}>
                                             ₦{newLoading ? <Spinner size='sm' /> : parseFloat(stat.total_incomes).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
                                        </div>
                                        <div style={{
                                            position: "absolute",
                                            top: "35%",
                                            left: "25%",
                                            transform: "translate(-50%, -50%)",
                                            color: "#2D995F",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            textAlign: "center",
                                        }}>
                                            Total Income
                                        </div>
                                    </div>
                                    <div className={classes.innerasdfas121}>
                                        {/* <img src={One} alt="img" style={{ width: "100%", height: "100%", objectFit: "contain" }} /> */}
                                        <div style={{
                                            position: "absolute",
                                            top: "40px",
                                            left: "85px",
                                            transform: "translateX(-40%)",
                                            color: "#2D995F",
                                            fontWeight: 800,
                                            fontSize: "20px",
                                            textAlign: "right",
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all',
                                            maxWidth: "1200px",
                                        }}>
                                             ₦{newLoading ? <Spinner size='sm' /> : parseFloat(stat.total_expenses).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
                                        </div>
                                        <div style={{
                                            position: "absolute",
                                            top: "35%",
                                            left: "25%",
                                            transform: "translate(-50%, -50%)",
                                            color: "#2D995F",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            textAlign: "center",
                                        }}>
                                            Total Expenses
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.dashboardasdf112leftasa}>
                                    <div className={classes.innerasdfas121}>
                                        {/* <img src={One} alt="img" style={{ width: "100%", height: "100%", objectFit: "contain" }} /> */}
                                        <div style={{
                                            position: "absolute",
                                            top: "40px",
                                            left: "93px",
                                            transform: "translateX(-40%)",
                                            color: "#2D995F",
                                            fontWeight: 800,
                                            fontSize: "20px",
                                            textAlign: "right",
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all',
                                            maxWidth: "1200px",
                                        }}>
                                             ₦{newLoading ? <Spinner size='sm' /> : parseFloat(stat.total_receivables).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
                                        </div>
                                        <div style={{
                                            position: "absolute",
                                            top: "35%",
                                            left: "37%",
                                            transform: "translate(-50%, -50%)",
                                            color: "#2D995F",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            textAlign: "center",
                                        }}>
                                            Account Receivables
                                        </div>
                                    </div>
                                    <div className={classes.innerasdfas121}>
                                        {/* <img src={One} alt="img" style={{ width: "100%", height: "100%", objectFit: "contain" }} /> */}
                                        <div style={{
                                            position: "absolute",
                                            top: "40px",
                                            left: "85px",
                                            transform: "translateX(-40%)",
                                            color: "#2D995F",
                                            fontWeight: 800,
                                            fontSize: "20px",
                                            textAlign: "right",
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all',
                                            maxWidth: "1200px",
                                        }}>
                                          ₦{newLoading ? <Spinner size='sm' /> : parseFloat(stat.total_payables).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}  
                                        </div>
                                        <div style={{
                                            position: "absolute",
                                            top: "35%",
                                            left: "30%",
                                            transform: "translate(-50%, -50%)",
                                            color: "#2D995F",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            textAlign: "center",
                                        }}>
                                            Account Payables
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.dashboardasdf112right}>
                                <h4 style={{ backgroundColor: '#2D995F', color: 'white', margin: 0, padding: '5px' }}>Aged Receivables</h4>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ width: '40%' }}>
                                        <Chart
                                            options={pieChartOptions}
                                            series={pieChartOptions?.series?.length ? pieChartOptions.series : []}
                                            type="donut"
                                            height={170}
                                        />
                                        <p style={{ marginTop: '-5px' }}>View Customer List</p>
                                    </div>
                                    <div style={{ width: '60%' }}>
                                        <div className="table-responsive">
                                            <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                                <thead style={{ whiteSpace: 'nowrap' }}>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Amount</th>
                                                        <th>VAT</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ whiteSpace: 'nowrap', }}>
                                                    <tr>
                                                        <td>1</td>
                                                        <td style={{ textAlign: 'right' }}>$20,000</td>
                                                        <td>6.5%</td>
                                                        <td>Paid</td>
                                                    </tr>
                                                </tbody>
                                                <tbody style={{ whiteSpace: 'nowrap', }}>
                                                    <tr>
                                                        <td>2</td>
                                                        <td style={{ textAlign: 'right' }}>$50,000</td>
                                                        <td>7.5%</td>
                                                        <td>Unpaid</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p style={{ marginTop: '10px' }}>View Aged Receivables Report </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.dashboardasdf112as}>
                            <div >
                                <div className={classes.firstGraphadf}>
                                    <div className={classes.secondGraph}>
                                        <Chart options={barChartOptions} series={barChartOptions.series} type="bar" height={180} width={500} />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.dashboardasdf112right}>
                                <h4 style={{ backgroundColor: '#2D995F', color: 'white', margin: 0, padding: '5px' }}>Aged Payables</h4>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ width: '40%' }}>
                                        <Chart
                                            options={pieChartOptions}
                                            series={pieChartOptions?.series?.length ? pieChartOptions.series : []}
                                            type="donut"
                                            height={170}
                                        />
                                        <p style={{ marginTop: '-5px' }}>View Supplier List</p>
                                    </div>
                                    <div style={{ width: '60%' }}>
                                        <div className="table-responsive">
                                            <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                                <thead style={{ whiteSpace: 'nowrap' }}>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Amount</th>
                                                        <th>VAT</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ whiteSpace: 'nowrap', }}>
                                                    <tr>
                                                        <td>1</td>
                                                        <td style={{ textAlign: 'right' }}>$20,000</td>
                                                        <td>6.5%</td>
                                                        <td>Paid</td>
                                                    </tr>
                                                </tbody>
                                                <tbody style={{ whiteSpace: 'nowrap', }}>
                                                    <tr>
                                                        <td>2</td>
                                                        <td style={{ textAlign: 'right' }}>$50,000</td>
                                                        <td>7.5%</td>
                                                        <td>Unpaid</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p style={{ marginTop: '10px' }}>View Aged Payables Report </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.dashboardasdf112asdfs}>
                        <div>
  <div className={classes.dashboardasdf112right12}>
    <h4 style={{ backgroundColor: '#2D995F', color: 'white', margin: 0, padding: '5px' }}>Top Customers</h4>
    <div style={{ width: '100%', maxHeight: '400px', overflowY: 'auto', overflowX: 'auto' }}>
      {newLoading ? (
        <p>Fetching customers...</p>
    ) : customer.length === 0 ? ( 
        <p>No data to display</p>
      ) : (
        <div className="table-responsive" style={{ minWidth: '100%' }}>
          <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
            <thead style={{ whiteSpace: 'nowrap' }}>
              <tr>
                <th>S/N</th>
                <th>Customer</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody style={{ whiteSpace: 'nowrap' }}>
              {customer.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ whiteSpace: 'normal' }}>{item.name}</td>
                  <td style={{ whiteSpace: 'wrap', textAlign: 'right' }}>
                    {parseFloat(item.balance).toLocaleString('en-US', {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  </div>
</div>

                            <div >
                                <div className={classes.dashboardasdf112right12}>
                                    <h4 style={{ backgroundColor: '#2D995F', color: 'white', margin: 0, padding: '5px' }}>Account Balance</h4>
                                    <div style={{ width: '100%', maxHeight: '400px', overflowY: 'auto', overflowX: 'auto' }}>
      {newLoading ? (
        <p>Fetching accounts...</p>
    ) : customer.length === 0 ? ( 
        <p>No data to display</p>
      ) : (
        <div className="table-responsive" style={{ minWidth: '100%' }}>
          <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
            <thead style={{ whiteSpace: 'nowrap' }}>
              <tr>
                <th>S/N</th>
                <th>Account</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody style={{ whiteSpace: 'nowrap' }}>
              {customer.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ whiteSpace: 'normal' }}>{item.name}</td>
                  <td style={{ whiteSpace: 'wrap', textAlign: 'right' }}>
                    {parseFloat(item.balance).toLocaleString('en-US', {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
                                </div>
                            </div>
                            <div className={classes.dashboardasdf112right12}>
                                <h4 style={{ backgroundColor: '#2D995F', color: 'white', margin: 0, padding: '5px' }}>Receivables</h4>
                                <div style={{ width: '100%', maxHeight: '400px', overflowY: 'auto', overflowX: 'auto' }}>
  {newLoading ? (
    <p>Fetching accounts...</p>
  ) : recievables.length === 0 ? ( 
    <p>No data to display</p>
  ) : (
    <div className="table-responsive" style={{ minWidth: '100%' }}>
      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
        <thead style={{ whiteSpace: 'nowrap' }}>
          <tr>
            <th>S/N</th>
            <th>Customer</th>
            <th>Details</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody style={{ whiteSpace: 'nowrap' }}>
          {recievables.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td style={{ whiteSpace: 'normal' }}>{item.customer?.name}</td>
              <td style={{ whiteSpace: 'normal' }}>{item.description}</td>
              {/* <td style={{ whiteSpace: 'wrap', textAlign: 'right' }}>
                {parseFloat(item.amount).toLocaleString('en-US', {
                  minimumIntegerDigits: 1,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td> */}
              {/* <td style={{ whiteSpace: 'wrap', textAlign: 'right' }}>
                {parseFloat(item.amount_paid).toLocaleString('en-US', {
                  minimumIntegerDigits: 1,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td> */}
              <td style={{ whiteSpace: 'wrap', textAlign: 'right' }}>
                {parseFloat(item.balance).toLocaleString('en-US', {
                  minimumIntegerDigits: 1,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )}
</div>

                            </div>
                        </div>

                        {/* <div style={{ display: "flex", flexDirection: "row", gap: 10, marginTop: "2rem", marginBottom: "1rem" }}>
                            <div style={{ position: "relative", width: 350, height: 270 }}>
                                <img src={One} alt="img" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                <div style={{
                                    position: "absolute",
                                    top: "95px",
                                    left: "120px",
                                    transform: "translateX(-40%)",
                                    color: "white",
                                    fontWeight: 900,
                                    fontSize: "25px",
                                    textAlign: "right",
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-all',
                                    maxWidth: "1200px",


                                }}>
                                    ₦ 25,000,000.00
                                </div>
                                <div style={{
                                    position: "absolute",
                                    top: "55%",
                                    left: "22%",
                                    transform: "translate(-50%, -50%)",
                                    color: "white",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    textAlign: "center",
                                }}>
                                    Total Income
                                </div>


                            </div>
                            <div style={{ position: "relative", width: 350, height: 270 }}>
                                <img src={Two} alt="img" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                <div style={{
                                    position: "absolute",
                                    top: "95px",
                                    left: "130px",
                                    transform: "translateX(-50%)",
                                    color: "white",
                                    fontWeight: 900,
                                    fontSize: "25px",
                                    textAlign: "right",
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-all',
                                    maxWidth: "1200px",

                                }}>
                                    ₦ 25,000,000.00

                                </div>
                                <div style={{
                                    position: "absolute",
                                    top: "55%",
                                    left: "26%",
                                    transform: "translate(-50%, -50%)",
                                    color: "white",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    textAlign: "center",
                                }}>
                                    Total Expenses
                                </div>

                            </div>
                            <div style={{ position: "relative", width: 350, height: 270 }}>
                                <img src={Four} alt="img" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                <div style={{
                                    position: "absolute",
                                    top: "95px",
                                    left: "120px",
                                    transform: "translateX(-40%)",
                                    color: "white",
                                    fontWeight: 900,
                                    fontSize: "25px",
                                    textAlign: "right",
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-all',
                                    maxWidth: "1200px",
                                }}>
                                    ₦ 25,000,000.00
                                </div>
                                <div style={{
                                    position: "absolute",
                                    top: "55%",
                                    left: "20%",
                                    transform: "translate(-50%, -50%)",
                                    color: "white",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    textAlign: "center",
                                }}>
                                    Total Profit
                                </div>

                            </div>
                        </div> */}

                        {/* <div fluid className={classes.chartCont1ss}>                         

                            <div className={classes.firstGraph}>
                                <div style={{ marginBottom: '20px', display: "flex", flexDirection: 'column', width: "200px" }}>
                                    <h4 style={{ fontWeight: '700', fontSize: '16px', color: '#000' }}>Dashboard</h4>
                                    <p style={{ marginTop: -10 }}>Overview of the latest month</p>
                                    <h2 className={classes.amount}>₦500,000,000,000,000.00</h2>
                                    <p style={{ marginTop: -10 }}>Current Month Income</p>
                                    <div>
                                        <h4 className={classes.amount}>50</h4>
                                        <p style={{ marginTop: -10 }}>Current Month Invoices</p>
                                    </div>
                                    <div>
                                        <h4 className={classes.amount}>50</h4>
                                        <p style={{ marginTop: -10 }}>Current Month Purchases</p>
                                    </div>
                                </div>

                               
                                <div className={classes.secondGraph}>
                                    <Tabs defaultActiveKey="daily" id="income-expenses-tabs" className="mb-3 complete-tabs" variant="underline" color="#2D995F">
                                        <Tab eventKey="daily" title="Daily">
                                            <Chart options={areaChartOptions} series={areaChartOptions.series} type="area" height={275} />
                                        </Tab>
                                        <Tab eventKey="weekly" title="Weekly">
                                            <Chart options={areaChartOptions} series={areaChartOptions.series} type="area" height={275} />
                                        </Tab>
                                        <Tab eventKey="monthly" title="Monthly">
                                            <Chart options={areaChartOptions} series={areaChartOptions.series} type="area" height={275} />
                                        </Tab>
                                        <Tab eventKey="yearly" title="Yearly">
                                            <Chart options={areaChartOptions} series={areaChartOptions.series} type="area" height={275} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>



                            <div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', background: '#fff', borderRadius: '8px', height: '350px', boxShadow: "0px 4px 8px rgba(43, 99, 43, 0.568)" }}>
                                    <h5>Income Distribution (Last 4 Months)</h5>
                                    <Chart
  options={pieChartOptions}
  series={pieChartOptions?.series?.length ? pieChartOptions.series : []}
  type="donut"
  height={300}
/>
                                </div>
                            </div>
                        </div> */}
                        {/* 
                        <div fluid className={classes.chartCont1ss}>


                            <div className={classes.firstGraph}>

                                <div className={classes.secondGraph}>
                                    <Chart options={barChartOptions} series={barChartOptions.series} type="bar" height={330} width={650} />
                                </div>
                            </div>
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', background: '#fff', borderRadius: '8px', height: '350px', boxShadow: "0px 4px 8px rgba(43, 99, 43, 0.568)" }}>
                                    <h5>Income Distribution (Last 4 Months)</h5>
                                    <Chart
                                        options={pieChartOptions}
                                        series={pieChartOptions?.series?.length ? pieChartOptions.series : []}
                                        type="donut"
                                        height={300}
                                    />
                                </div>
                            </div>
                        </div> */}
                    </div>


                </div>


            </div>

        </div>

    );
}

export default LatestAccountingDashboard;