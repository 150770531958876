import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
// import CurrencyInput from 'react-currency-input-field';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import classes from './CreateSales.module.css';
import classes from '../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../Main Dashboard/MainDashoard';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import CashDash from '../Cash Dash/CashDash';
import PaymentDash from '../Payment Dashboard/PaymentDash';
import LatestDash from '../LatestDashboard/LatestDash';
// import { BASE_URL } from '../api/api';
// import { BASE_URL } from '../../api/api';
// import classes from './LoanRepayment.module.css'
// import favicon from '../../Images/faviconn.png'

function CreateExpenses() {
    const [receive, setReceive] = useState('');
    const [subCat, setSubcat] = useState([]);
    const [subCat1, setSubcat1] = useState([]);
    const [paymentMeth, setPaymentMeth] = useState([]);
    const [teller, setTeller] = useState('');
    const [description, setDescription] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [currency, setCurrency] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState('');
    const [selectedDebit, setSelectedDebit] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bearer, setBearer] = useState('');
    const navigate = useNavigate();
    const [formData, setFormData] = useState([{ sn: 1, accountName: '', accountCode: '', amount: '' }]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [incomeAmount, setIncomeAmount] = useState("");
    const [totalAmount, setTotalAmount] = useState('');
    const [debitAccount, setDebitAccount] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectOptions, setSelectOptions] = useState([]);
    const [currLoading, setCurrLoading] = useState(false);


    const [user, setUser] = useState('');
    const [userType, setUserType] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');



    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };
    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };
    const handleDebitChange = (event) => {
        setSelectedDebit(event.target.value);
    };


    const calculateTotalAmount = () => {
        const total = formData.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
        const formattedTotal = total.toLocaleString('en-US', {
            minimumIntegerDigits: 1,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        setTotalAmount(formattedTotal);
    };


    useEffect(() => {

        calculateTotalAmount();
    }, [formData]);





    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('tobi');
            const admins = await AsyncStorage.getItem('admin');
            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');

            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setUser(value1);
            }
            if (admins !== null) {
                setUserType(admins);
            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const goBack = () => {
        navigate(-1);
    }

    const addRow = () => {
        const newRow = {
            sn: formData.length + 1,
            accountName: '',
            accountCode: '',
            amount: '',
        };
        setFormData([...formData, newRow]);
        setSelectedPayment('');
    };

    const deleteRow = (index) => {
        const updatedData = formData.filter((_, i) => i !== index);
        setFormData(updatedData);
    };

    const handleFormChange = (index, field, value) => {
        const updatedFormData = [...formData];
        const numericValue = value.replace(/\D/g, '');
        const numericAmount = numericValue !== '' ? parseFloat(numericValue) : '';
        const formattedValue = numericAmount !== '' ? numericAmount.toLocaleString() : '';

        updatedFormData[index][field] = formattedValue;
        setFormData(updatedFormData);
    };

    const fetchSubCat = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${BASE_URL}/get-account-by-class-id?class_id=${1}`,
                {

                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );
            const results = response.data?.data;
            setSubcat(results);

            console.log(results, "Credit Accounts");
        } catch (error) {
            const errorStatus = error.response.data.message;
            console.error(errorStatus);
        } finally {
            setLoading(false);
        }
    };

    const fetchAccountName = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${BASE_URL}/get-account-by-class-id?class_id=${5}`,
                {

                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );
            const results = response.data?.data;
            setSubcat1(results);

            //   console.log(results, "NIYIN");
        } catch (error) {
            const errorStatus = error.response.data.message;
            console.error(errorStatus);
        } finally {
            setLoading(false);
        }
    };







    // console.log(formData);
    const createIncome = async () => {
        setIsLoading(true);

        try {
            const accountNames = formData.map((row) => row.accountName).filter((name) => name !== undefined);
            const amounts = formData.map((row) => row.amount).filter((name) => name !== undefined);
            // const amount1 = incomeAmount.replace(/,/g, '').replace(/\.00$/, '');

            console.log(accountNames, amounts, incomeAmount, "information")
            // return;

            const response = await axios.post(`${BASE_URL}/create-new`,
                {
                    account_id: accountNames,
                    gl_code: selectedDebit,
                    description: description,
                    payment_mode: selectedPayment,
                    breakdown_amount: amounts,
                    total_amount: incomeAmount,
                    teller_no: teller,
                    particulars: receive,
                    transaction_date: selectedDate,
                    currency: selectedCurrency.value || '',
                    type: 2
                },
                { headers }
            );

            console.log(response.data, "heeee");
            setReceive("");
            setSelectedDebit("");
            setSelectedPayment("");
            setReceive("");
            setTeller("");
            setDescription("");
            setCurrency("");
            setSelectedDate('');
            navigate(-1);

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
            });


        } catch (error) {
            const errorStatus = error.response.data.message;
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: errorStatus,
            });
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };




    useEffect(() => {
        if (bearer) {
            fetchSubCat();
            fetchAccountName();

        }
    }, [bearer]);





    const handleAccountChange = (index, event) => {
        const selectedAccount = event.target.value;
        const intselectedId = parseInt(selectedAccount);
        const selectedGlCode = subCat1.find((item) => item.id === intselectedId)?.gl_code || '';

        const updatedFormData = [...formData];
        updatedFormData[index] = {
            ...updatedFormData[index],
            accountName: selectedAccount,
            accountCode: selectedGlCode,
        };

        setFormData(updatedFormData);
    };

    const handleValueChange = (value, name, values) => {
        setIncomeAmount(value); // Update the balance state
        console.log(value, name, values);
    };

    const handleValueChange1 = (value, index) => {
        const updatedFormData = [...formData];
        updatedFormData[index] = {
            ...updatedFormData[index],
            amount: value,
        };
        setFormData(updatedFormData);
        calculateTotalAmount(); // Recalculate total amount after each change
    };

    const fetchCurrency = async () => {
        setCurrLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/fetch-all-currencies`, { headers });
            const results = response.data?.data;
            // console.log(results, "currencis")
            const options = results.map((item) => ({
                label: item.code,
                value: item.id,
                symbol: item.symbol
            }));
            setCurrency(results);
            setSelectOptions(options);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setCurrency([]);
        } finally {
            setCurrLoading(false);
        }
    };

    useEffect(() => {
        if (bearer) {
            fetchCurrency();
        }
    }, [bearer]);


    return (

        <div>

            <div className="wrapper">
                {/* <!-- Sidebar  --> */}


                {/* <!-- Page Content  --> */}
                <div className="content-wrapper">
                    <div className="main-content">

                        <LatestDash />
                        <div className='newBody'>


                            <div className={classes.finishedbodyCont}>
                                <div className={classes.newWidth}>

                                    <h3 style={{ marginTop: "4rem", fontSize: 30, fontWeight: 700 }}>Create Expenses</h3>


                                    <div className={classes.topexpensePadding}>
                                        <div className={`${classes.formSecCont}`}>
                                            <div className="card-body" style={{ border: 'none' }}>


                                                <div className="row">
                                                    <div className={`${classes.formSection} ${classes.formSectionGap}`} >
                                                        <div className="col-md-6" style={{ width: "100%" }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Particulars</label>
                                                                <div className="col-sm-8">
                                                                    <input className="form-control" required="" type="text" value={receive} onChange={(e) => setReceive(e.target.value)} name="receive" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6" style={{ width: "100%" }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Transaction Date</label>
                                                                <div className="col-sm-8">
                                                                    <input className="form-control" required="" type="date" onChange={handleDateChange} name="date" value={selectedDate} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 2, }} />
                                                    <div className={`${classes.formSection} ${classes.formSectionGap}`}>
                                                        <div className="col-md-6" style={{ width: "100%" }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Description</label>
                                                                <div className="col-sm-8">
                                                                <textarea
                                                                        style={{ maxWidth: "100%", }}
                                                                        className="form-control"
                                                                        rows="2"
                                                                        cols="10"
                                                                        required=""
                                                                        value={description}
                                                                        onChange={(e) => setDescription(e.target.value)}
                                                                        name="description"
                                                                    />                                                                
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6" style={{ width: "100%", marginTop: 12,  }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Currency</label>
                                                                <div className="col-sm-8">
                                                                <Select
                                                                            value={selectedCurrency}
                                                                            onChange={setSelectedCurrency}
                                                                            options={selectOptions}
                                                                            menuPortalTarget={document.body}
                                                                            styles={{
                                                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                                menu: (provided) => ({
                                                                                    ...provided,
                                                                                    maxHeight: '300px',
                                                                                    // maxWidth: '200px',
                                                                                    overflowY: 'auto',
                                                                                }),
                                                                            }}
                                                                        />  
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 2 }} />
                                                    <div className={`${classes.formSection} ${classes.formSectionGap}`}>

                                                        <div className="col-md-6" style={{ width: "100%" }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Payment Mode</label>
                                                                <div className="col-sm-8">
                                                                    <Form.Select name="customer" className="form-control" required="" value={selectedPayment} onChange={handlePaymentChange}>
                                                                        <option value="">Choose Payment Mode</option>
                                                                        <option value="1">Cash</option>
                                                                        <option value="2">Bank</option>
                                                                    </Form.Select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6" style={{ width: "100%" }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Teller/Cheque No</label>
                                                                <div className="col-sm-8">
                                                                    <input className="form-control" required="" type="text" value={teller} onChange={(e) => setTeller(e.target.value)} name="receive" />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6" style={{ width: "100%", marginTop: '-10px' }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Credit Account</label>
                                                                <div className="col-sm-8">
                                                                    <Form.Select name="customer" className="form-control" required="" value={selectedDebit} onChange={handleDebitChange}>
                                                                        <option value="">Choose Account</option>
                                                                        {subCat.map((item) => (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.gl_name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6" style={{ width: "100%", marginTop: '-10px' }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Amount</label>
                                                                <div className="col-sm-8">
                                                                    <CurrencyInput
                                                                        //   
                                                                        name="amount"
                                                                        decimalsLimit={2}
                                                                        className="form-control"
                                                                        value={incomeAmount} // Set the value to the balance state
                                                                        onValueChange={handleValueChange}
                                                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div style={{ marginTop: 10 }} />

                                        <div className="row" style={{ width: "100%" }}>
                                            {/* <div className="col-md-6"> */}
                                            <div className="table-responsive">
                                                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                                    <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                                        <tr>
                                                            <th>#</th>
                                                            <th style={{ width: '50%', }}>Account Name</th>
                                                            <th>Account Code</th>
                                                            <th>Amount</th>
                                                            <th ><Button style={{ borderRadius: 80 }} variant="primary" onClick={() => addRow()}>
                                                                <i className="fas fa-plus"></i>

                                                            </Button></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                                        {formData.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{row.sn}</td>
                                                                <td>
                                                                    <Form.Select
                                                                        className="form-control"
                                                                        value={row.accountName}
                                                                        onChange={(e) => handleAccountChange(index, e)}
                                                                    >
                                                                        <option value="">Select Account</option>
                                                                        {subCat1.map((item) => (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.gl_name}
                                                                            </option>
                                                                        ))}

                                                                    </Form.Select>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={row.accountCode}
                                                                        disabled

                                                                    />
                                                                </td>
                                                                <td>
                                                                    <CurrencyInput
                                                                        name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                                                                        decimalsLimit={2}
                                                                        value={row.amount}
                                                                        className="form-control"
                                                                        onValueChange={(value) => handleValueChange1(value, index)}
                                                                        style={{ textAlign: "right", border: "none" }}
                                                                    />

                                                                </td>
                                                                <td>
                                                                    <Button style={{ borderRadius: 80 }} variant="danger" onClick={() => deleteRow(index)}>
                                                                        <i className="far fa-trash-alt"></i>
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div style={{ marginTop: 30 }} />
                                                <div style={{ display: "flex", justifyContent: "flex-end", gap: '20px' }}>


                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Total Amount:</Form.Label>
                                                        <input style={{ textAlign: "right", }} className="form-control" required="" type="text" value={totalAmount} name="total" disabled />
                                                    </Form.Group>

                                                </div>

                                            </div>
                                        </div>
                                    <div class="modal-footer" style={{ marginRight: '20px' }}>
                                        <Button variant='success' onClick={createIncome}>
                                            {isLoading ? (
                                                <>
                                                    <Spinner size='sm' />
                                                    <span style={{ marginLeft: '5px' }}>Creating Expenses, Please wait...</span>
                                                </>
                                            ) : (
                                                "Create Expenses"
                                            )}
                                        </Button>
                                        {/* <Button>Save Changes</Button> */}
                                        {/* <button type="submit" class="btn btn-success"><span id="loaderg" className="spinner-border spinner-border-sm me-2" role="status" style={{display:"none",}}></span>Save changes</button> */}
                                    </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>













                    {/* </div> */}
                    {/* <!--/.main content--> */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* <InfoFooter /> */}
                    {/* <!--/.footer content--> */}
        //   <div className="overlay"></div>
        // </div>
                {/* <!--/.wrapper--> */}


            </div>
        </div>

    );
}

export default CreateExpenses;